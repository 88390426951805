/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

const $ = require('jquery');

// create global $ and jQuery variables - needed for bootstrap modal plugin
global.$ = global.jQuery = $;

require('popper.js');

// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap');
require('bootstrap-select');
require('bootstrap-datepicker');
require('moment');

//fontawesome
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

$('.js-datepicker').datepicker({
    format: 'yyyy-mm-dd'
});

$('.btn-show-information').click(function(){
  $(this).text($(this).text() == 'View' ? 'Hide' : 'View');
});

$('.basket-icon').click(function(){
    $('.tooltip-basket-text-large').fadeToggle();
    $('.tooltip-notifications-text-large').fadeOut();
});
$('#basket-close').click(function(){
    $('.tooltip-basket-text-large').fadeOut();
});

$('.notifications-icon').click(function(e){
    // e.preventDefault();
    $('.tooltip-notifications-text-large').fadeToggle();
    $('.tooltip-basket-text-large').fadeOut();
});

$('.new-notification').click(function(e) {
    // e.preventDefault();
    const id = $(this).data('notification-id');
    let number = parseInt($('.tooltip-notifications .badge').html());
    $('.tooltip-notifications .badge').html(number - 1);
    $(this).fadeOut();
    $.ajax({
        method: "DELETE",
        url: "/notifications/" + id
    }).done(() => {
        
    });
});

$('.basket-line-quantity').change(function() {
    var value = $(this).val();
    var basketLineId = $(this).data('basketline');
    $('.basket-line-hidden[name="basketLine[' + basketLineId + ']"]').val(value);
});